<template>
  <div class="empty_wrap" v-if="finishGetList">
    <div class="img_box"></div>
    <div class="text_box">{{$t('no_default_list')}}</div>
    <div class="qna_bottom">
      <button class="black-btn" @click="movePage('/qna/create')">{{$t('question')}}</button>
    </div>
  </div>
    <div class="empty_wrap" v-else v-loading="!finishGetList" :element-loading-text="''" element-loading-custom-class="dgmong_loading"></div>
</template>

<script>
export default {
  name: "BoardEmptyQnaLayout",
  mixins: [],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
      finishGetList: {default: true,}
  },
  data() {
    return{

    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    moveDetail(data){
      this.$router.push(`/qna/${data}`)
    },
    movePage(url) {
      this.$router.push(url);
    },
  },
  watch: {

  },
}
</script>

<style scoped>

</style>
